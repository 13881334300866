.circleRow {
  /* width: 100%; */
  background-color: red;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  overflow-x: scroll;
}
.circleRow::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
