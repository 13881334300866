@font-face {
  font-family: "Styrene";
  src: url(./assets/fonts/StyreneAWeb-Regular.ttf);
  /* src: local("Styrene"),
    url(./assets/fonts/StyreneAWeb-Regular.ttf) format("ttf"); */
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Styrene", sans-serif;
  /* background-color: #0448a3; */
}
