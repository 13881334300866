.live-icon {
  display: flex;
  flex-direction: row;
  height: 28px;
  background-color: red;
  position: relative;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  border: 1px solid red;
  width: 75px;
}

.live-icon:before {
  content: "";
  /* border: 1px solid red; */
  border-color: red;
  border-style: solid;
  display: block;
  position: absolute;
  /* transform: translate(-50%, -50%); */
  width: 100%;
  min-height: 28px;
  border-radius: 14px;
  animation-duration: 3s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: both;
  animation-play-state: running;
  animation-name: live;
  /* z-index: 1; */
}

@keyframes live {
  0% {
    transform: scale(1, 1);
    border: 1px solid transparent;
  }
  9% {
    border: 1px solid rgba(255, 0, 0, 0.5);
  }
  10% {
    border: 1px solid rgba(255, 0, 0, 0.5);
  }
  50% {
    transform: scale(1.4, 1.85);
    border-radius: 52px;
    border: 1px solid transparent;
  }
  100% {
    transform: scale(1.4, 1.85);
    border: 1px solid transparent;
  }
}
