@import url(https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css);
body {
  margin: 0;
  font-family: Styrene;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Styrene";
  src: url(/static/media/StyreneAWeb-Regular.07e7d6b6.ttf);
  /* src: local("Styrene"),
    url(./assets/fonts/StyreneAWeb-Regular.ttf) format("ttf"); */
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Styrene", sans-serif;
  /* background-color: #0448a3; */
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 40%, 0);
            transform: translate3d(0, 40%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 40%, 0);
            transform: translate3d(0, 40%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
}

@-webkit-keyframes fadeInUpSlow {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 10%, 0);
            transform: translate3d(0, 10%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUpSlow {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 10%, 0);
            transform: translate3d(0, 10%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

.fadeInUpSlow {
  -webkit-animation-name: fadeInUpSlow;
          animation-name: fadeInUpSlow;
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(10%, 0, 0);
            transform: translate3d(10%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(10%, 0, 0);
            transform: translate3d(10%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
          animation-name: fadeInRight;
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-10%, 0, 0);
            transform: translate3d(-10%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-10%, 0, 0);
            transform: translate3d(-10%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
          animation-name: fadeInLeft;
}

.AppStoreIcon {
  margin-right: 0.75em;
  height: 1em;
  font-size: 0.9375rem;
  font-family: Styrene, sans-serif;
  white-space: nowrap;
  letter-spacing: 0.04em;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 500;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  /* color: white; */
}

.circleRow {
  /* width: 100%; */
  background-color: red;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  overflow-x: scroll;
}
.circleRow::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.live-icon {
  display: flex;
  flex-direction: row;
  height: 28px;
  background-color: red;
  position: relative;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  border: 1px solid red;
  width: 75px;
}

.live-icon:before {
  content: "";
  /* border: 1px solid red; */
  border-color: red;
  border-style: solid;
  display: block;
  position: absolute;
  /* transform: translate(-50%, -50%); */
  width: 100%;
  min-height: 28px;
  border-radius: 14px;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: normal;
          animation-direction: normal;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  -webkit-animation-name: live;
          animation-name: live;
  /* z-index: 1; */
}

@-webkit-keyframes live {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    border: 1px solid transparent;
  }
  9% {
    border: 1px solid rgba(255, 0, 0, 0.5);
  }
  10% {
    border: 1px solid rgba(255, 0, 0, 0.5);
  }
  50% {
    -webkit-transform: scale(1.4, 1.85);
            transform: scale(1.4, 1.85);
    border-radius: 52px;
    border: 1px solid transparent;
  }
  100% {
    -webkit-transform: scale(1.4, 1.85);
            transform: scale(1.4, 1.85);
    border: 1px solid transparent;
  }
}

@keyframes live {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    border: 1px solid transparent;
  }
  9% {
    border: 1px solid rgba(255, 0, 0, 0.5);
  }
  10% {
    border: 1px solid rgba(255, 0, 0, 0.5);
  }
  50% {
    -webkit-transform: scale(1.4, 1.85);
            transform: scale(1.4, 1.85);
    border-radius: 52px;
    border: 1px solid transparent;
  }
  100% {
    -webkit-transform: scale(1.4, 1.85);
            transform: scale(1.4, 1.85);
    border: 1px solid transparent;
  }
}

